import { login, logout, getUserIfno, userReport } from '@/api/user/index.js'
import { getToken, setToken, removeToken, clearAuth } from '@/util/auth'
import { getQueryVariable, getGAClientID } from '@/util/index'
import jwt_decode from 'jwt-decode'
import firebase from '@/plugins/firebase'

const state = {
  token: getToken(),
  user_id: '',
  name: '',
  avatar: '',
  userInfo: null,
  sub_data: [],
  roles: [],
  analytics: null,
  app_download_url: 'https://onechannel.onelink.me/cjtv',
  special_channels: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_USER_ID: (state, user_id) => {
    state.user_id = user_id
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USERIFNO: (state, data) => {
    state.userInfo = data
  },
  SET_SPECIAL_CHANNELS: (state, data) => {
    state.special_channels = data
  },
  SET_SUB_DATA: (state, data) => {
    state.sub_data = data
  },
  SET_LOGEVENT: (state, data) => {
    if (!state.analytics) {
      state.analytics = firebase.analytics()
    }
    state.analytics.logEvent(data.eventName, data.params)
  },
  SET_APP_DOWNLOAD_URL: (state, data) => {
    state.app_download_url = data
  }
}

const actions = {
  logEvent({ commit }, data) {
    // commit('SET_LOGEVENT', data)
    window.dataLayer.push({ 'event': data.eventName, ...data.params })
  },
  // user login
  login({ commit }, data) {
    data['ref_id'] = getQueryVariable('ref_id')
    return new Promise((resolve, reject) => {
      login(data).then(res => {
        const data = res.data
        if ('token' in data === false) {
          resolve(data)
          return
        }
        setToken(data.token)
        commit('SET_TOKEN', data.token)
        var decoded = jwt_decode(data.token)
        // console.log(decoded)
        if (window.dataLayer) {
          window.dataLayer.push({
            'user_id': decoded.username
          })
        }
        // 使用已登录的 user_id 来设置用户 ID。
        resolve(decoded)
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getUserIfno({ 'env': 'web' }).then(response => {
        const data = response.data
        if (response.code !== 0) {
          reject('Verification failed, please Login again.')
          return
        }

        commit('SET_USER_ID', data.user.uid)
        commit('SET_NAME', data.user.uid)
        commit('SET_AVATAR', data.user.photo_url)

        commit('SET_SPECIAL_CHANNELS', data.special_channels)

        userReport({ 'client_id': getGAClientID() })

        if (window.dataLayer) {
          window.dataLayer.push({
            'user_id': data.user.uid
          })
        }
        // 使用已登录的 user_id 来设置用户 ID。

        var userId = data.user.uid || null // Replace your_user_id with your own if available.
        if (window.hj) {
          window.hj('identify', userId, {
            email: data.user.email,
            name: data.user.nickname
          })
        }

        if (window.Intercom) {
          window.Intercom('boot', {
            app_id: 'ei6khlkl',
            name: data.user.nickname, // Full name
            email: data.user.email, // Email address
            user_id: data.user.uid,
            uid: data.user.uid, // customer user attribute
            created_at: Math.floor(new Date(data.user.date_joined).getTime() / 1000) // Signup date as a Unix timestamp
          })

          if (window.isWebKit() || window.isAndroidWebView()) { // webview env
            window.Intercom('update', {
              'hide_default_launcher': true
            })
          } else {
            window.Intercom('update')
          }
        }

        commit('SET_USERIFNO', response.data)
        if (data.subscription) {
          const sub_data = data.subscription.platforms.split(';').map(name => {
            return { 'platform_name': name }
          })
          commit('SET_SUB_DATA', sub_data)
        }
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        commit('SET_TOKEN', '')
        removeToken()
        // resetRouter()
        resolve()
        location.href = '/'
      }).catch(error => {
        reject(error)
      })
    })
  },
  // 前端 登出
  FedLogOut({ commit }) {
    clearAuth()
    location.href = '/'
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      removeToken()
      resolve()
    })
  },
  updateAppDownloadUrl({ commit }, data) {
    commit('SET_APP_DOWNLOAD_URL', data)
  },
  setSubData({ commit }, data) {
    commit('SET_SUB_DATA', data)
  },
  getNativeAppInfo() {
    return new Promise((resolve, reject) => {
      try {
        if (window.isWebKit()) { // ios webview env
          window.setupWKWebViewJavascriptBridge((bridge) => {
            bridge.callHandler('getAppInfo', '', (data) => {
              // data -> {"app_name": "One Channel Mobile", "package": "WKChannel.Mobile.App", "platform": "ios", "app_version": "2.0.1"}
              resolve(data)
            })
          })
        } else if (window.isAndroidWebView()) {
          // window.JsBridge.callMethod('JsInvokeJavaScope', 'getAppInfo', {}, function(responseData) {
          //   alert('android getAppInfo: ' + JSON.stringify(responseData))
          //   resolve(responseData.data)
          // })
          // 暂时采用user agent 的方式处理
          const res_data = {
            userAgent: window.navigator.userAgent
          }
          resolve(res_data)
        }
      } catch (error) {
        resolve(null)
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

